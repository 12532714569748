const Table = () => {
    
    return (
        <div className="table">
            <div className="table__top">
                <div className="table__title">
                    Status
                </div>
                <div className="table__title">
                  Address
                </div>
                <div className="table__title">
                  From-To
                </div>
                <div className="table__title">
                  Payout
                </div>
                <div className="table__title">
                  Random number
                </div>
            </div> 
        </div>
    );
  };
  
export default Table;